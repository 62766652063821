import React from 'react';
import * as Icons from 'react-feather';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';


const Title = styled.div`
  height: 40px;
  & h5, & h5 a {
    color: ${opts => opts.color || 'inherit'};
  }
`;
const JobActions = styled.div`
  cursor: pointer;
  & svg {
    stroke: ${colors.grey4};
  }
`;
const Dropdown = styled.div`
  padding: 0px;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 0px 4px 15px 0px rgba(197, 197, 197, 1);
`;
const DropdownItem = styled.div`
  cursor: pointer;
  :hover {
    background-color: ${colors.grey2};
  }
`;
const DropdownItemA = styled.a`
  cursor: pointer;
  :hover {
    background-color: ${colors.grey2};
  }
`;
const ImportLink = styled.a`
  color: ${colors.body};
`;

export default class JobTitle extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      menuOpen: false
    };
    this.menu = null;
    this.dropdown = React.createRef();
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onDuplicate = this.onAction.bind(this, opts.onDuplicate);
    this.onImport = this.onAction.bind(this, opts.onImport);
    this.onInfosheet = this.onAction.bind(this, opts.onInfosheet);
    this.onJobsheet = this.onAction.bind(this, opts.onJobsheet);
    this.onLightTunnel = this.onAction.bind(this, opts.onLightTunnel);
    this.onQuote = this.onAction.bind(this, opts.onQuote);
    this.onExcessInvoice = this.onAction.bind(this, opts.onExcessInvoice);
    this.onInvoice = this.onAction.bind(this, opts.onInvoice);
    this.onOrmQuote = this.onOrm.bind(this, 'quote');
    this.onOrmDecline = this.onOrm.bind(this, 'decline');
    this.onOrmInvoice = this.onOrm.bind(this, 'invoice');
    this.onCancelInvoice = this.onCancelInvoice.bind(this);
    this.onArchive = this.onArchiveStatus.bind(this, true);
    this.onUnarchive = this.onArchiveStatus.bind(this, false);
  }
  componentDidMount() {
    document.addEventListener('click', this.onDocumentClick);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick);
  }
  onDocumentClick(e) {
    if (this.dropdown.current !== null) {
      const node = ReactDOM.findDOMNode(this.dropdown.current);
      if (!node.contains(e.target) && !this.menu.contains(e.target)) {
        this.setState({
          menuOpen: false
        });
      }
    }
  }
  onToggleMenu() {
    this.setState({
      ...this.state,
      menuOpen: !this.state.menuOpen
    });
  }
  onAction(fn) {
    const {jobId} = this.props;
    this.setState({
      menuOpen: false
    });
    fn(jobId);
  }
  onOrm(action) {
    this.setState({
      menuOpen: false
    });
    if (window.confirm(`Are you sure you wish to ${action} this job on ORM?`) === true) {
      const {jobId, onOrm} = this.props;
      onOrm(jobId, action)
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered while updating job.');
          } else {
            toast.success('Job successfully updated.');
          }
        })
        .catch(e => {
          console.log(e);
          toast.error('Error encountered while updating job.');
        });
    }
  }
  onCancelInvoice() {
    this.setState({
      menuOpen: false
    });
    if (window.confirm(`Are you sure you wish to cancel the invoice on this job?`) === true) {
      const {jobId, onCancelInvoice} = this.props;
      onCancelInvoice(jobId)
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered while changing invoice status.');
          } else {
            toast.success('Job successfully updated.');
          }
        })
        .catch(e => {
          console.log(e);
          toast.error('Error encountered while changing invoice status.');
        });
    }
  }
  onArchiveStatus(set) {
    this.setState({
      menuOpen: false
    });
    if (window.confirm(`Are you sure you wish to ${set ? 'archive' : 'unarchive'} this job?`) === true) {
      const {jobId, onArchive} = this.props;
      onArchive(jobId, set)
        .then(res => {
          if (res !== true) {
            toast.error('Error encountered while changing archive status.');
          } else {
            toast.success('Job successfully updated.');
          }
        })
        .catch(e => {
          console.log(e);
          toast.error('Error encountered while changing archive status.');
        });
    }
  }
  render() {
    const {
      canAddJob,
      canLightTunnel,
      canJobArchive,
      canJobDetailsSave,
      canJobDetailsViewQuotePrices,
      canJobInvoice,
      canJobCancelInvoice,
      canJobExcessInvoice,
      canJobOrm,
      canViewCustomerPortal,
      canViewClaimPortal,
      canViewOrmPortal,
      hasDropdown,
      hasOrm,
      customerPortalUrl,
      claimPortalUrl,
      jobNumber,
      flagged,
      vpp,
      vip,
      importUrl,
      isArchived,
      isInvoiced,
      isPaid,
      claimNumber,
    } = this.props;
    const {menuOpen} = this.state;
    let alertColor = colors.body;
    if (flagged === true) alertColor = colors.red;
    else if (vpp === true) alertColor = colors.orange;
    else if (vip === true) alertColor = colors.yellow;
    const canCancelInvoice = canJobCancelInvoice === true && isInvoiced === true && isPaid !== true;
    return (
      <Title
        className='d-flex flex-row align-items-center'
        color={alertColor}
      >
        {importUrl !== null && (
          <h5 className='m-0 mr-2 p-0'>Job #: <ImportLink href={importUrl} target={jobNumber}>{jobNumber}</ImportLink></h5>
        )}
        {importUrl === null && <h5 className='m-0 mr-2 p-0'>Job #: {jobNumber}</h5>}
        {hasDropdown === true && (
          <div className='dropdown' ref={this.dropdown}>
            <JobActions
              ref={ref => this.menu = ref}
              onClick={this.onToggleMenu}
            ><Icons.MoreHorizontal width={24} height={24} /></JobActions>
            {menuOpen === true && (
              <Dropdown className='dropdown-menu show'>
                {canAddJob && (
                  <DropdownItem className='dropdown-item p-2' onClick={this.onDuplicate}>
                    Duplicate
                  </DropdownItem>
                )}
                {canJobDetailsSave && (
                  <DropdownItem className='dropdown-item p-2' onClick={this.onImport}>
                    Import
                  </DropdownItem>
                )}
                <DropdownItem className='dropdown-item p-2' onClick={this.onInfosheet}>
                  Infosheet
                </DropdownItem>
                <DropdownItem className='dropdown-item p-2' onClick={this.onJobsheet}>
                  Jobsheet
                </DropdownItem>
                {canLightTunnel && (
                  <DropdownItem className='dropdown-item p-2' onClick={this.onLightTunnel}>
                    Light tunnel
                  </DropdownItem>
                )}
                {canJobDetailsViewQuotePrices && (
                  <DropdownItem className='dropdown-item p-2' onClick={this.onQuote}>
                    Quote
                  </DropdownItem>
                )}
                {hasOrm !== true && canJobInvoice && (
                  <DropdownItem className='dropdown-item p-2' onClick={this.onInvoice}>
                    Invoice
                  </DropdownItem>
                )}
                {canJobExcessInvoice && (
                  <DropdownItem className='dropdown-item p-2' onClick={this.onExcessInvoice}>
                    Invoice - excess
                  </DropdownItem>
                )}
                {hasOrm && canJobOrm && (
                  <React.Fragment>
                    <hr className='m-0' />
                    <DropdownItem
                      className='dropdown-item p-2'
                      onClick={this.onOrmQuote}
                    >{'ORM \u2013 quote'}</DropdownItem>
                    <DropdownItem
                      className='dropdown-item p-2'
                      onClick={this.onOrmInvoice}
                    >{'ORM \u2013 invoice'}</DropdownItem>
                    <DropdownItem
                      className='dropdown-item p-2'
                      onClick={this.onOrmDecline}
                    >{'ORM \u2013 decline'}</DropdownItem>
                  </React.Fragment>
                )}
                {(canViewCustomerPortal || canViewClaimPortal) && (
                  <React.Fragment>
                    <hr className='m-0' />
                    {canViewCustomerPortal && (
                      <DropdownItemA
                        href={customerPortalUrl}
                        className='dropdown-item p-2'
                        target='_blank'
                      >
                        Customer portal
                      </DropdownItemA>
                    )}
                    {canViewClaimPortal && (
                      <DropdownItemA
                        href={claimPortalUrl}
                        className='dropdown-item p-2'
                        target='_blank'
                      >
                        Claim portal
                      </DropdownItemA>
                    )}
                    <hr className='m-0' />
                    {canViewOrmPortal && <DropdownItemA
                        href={`https://repx.iag.com.au/orm/ormweb/repairer/quoteSearch.do?claimNo=${claimNumber}`}
                        className='dropdown-item p-2'
                        target='_blank'
                      >
                        ORM portal
                      </DropdownItemA>
                    }
                  </React.Fragment>
                )}
                {(canJobArchive || canCancelInvoice) && (
                  <React.Fragment>
                    <hr className='m-0' />
                    {canCancelInvoice && (
                      <DropdownItem className='dropdown-item p-2 text-danger' onClick={this.onCancelInvoice}>
                        Cancel invoice
                      </DropdownItem>
                    )}
                    {canJobArchive && isArchived === false && (
                      <DropdownItem className='dropdown-item p-2 text-danger' onClick={this.onArchive}>
                        Archive
                      </DropdownItem>
                    )}
                    {canJobArchive && isArchived === true && (
                      <DropdownItem className='dropdown-item p-2 text-danger' onClick={this.onUnarchive}>
                        Unarchive
                      </DropdownItem>
                    )}
                  </React.Fragment>
                )}
              </Dropdown>
            )}
          </div>
        )}
      </Title>
    );
  }
};
