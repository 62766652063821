import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import Details from './component.jsx';


const change = (changes) => {
  if (!changes.hasOwnProperty('debtorId') && !changes.hasOwnProperty('stormId')) {
    return {
      type: 'JOB-DETAILS-HEADER-UPDATE',
      changes
    }
  } else {
    return (dispatch, getState) => {
      const {
        params: {
          generalSettings,
          debtors: {index: debtors},
          storms: {index: storms},
          companies: {index: companies},
        }
      } = getState();
      dispatch({
        type: 'JOB-DETAILS-HEADER-UPDATE',
        changes,
        generalSettings,
        debtors,
        storms,
        companies,
      });
    }
  }
};

const load = (API, jobId) => {
  return (dispatch, getState) => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/job/${jobId}/details`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'JOB-DETAILS-SET-NOT-FOUND'
          });
          dispatch({
            type: 'JOB-GALLERY-SET',
            images: []
          });
          return null;
        }
        const {
          params: {
            generalSettings,
            debtors: {index: debtors},
            storms: {index: storms},
            companies: {index: companies},
          }
        } = getState();
        dispatch({
          type: 'JOB-DETAILS-PORTAL-SET',
          customerPortalUrl: resp.customerPortalUrl,
          claimPortalUrl: resp.claimPortalUrl,
        });
        dispatch({
          type: 'JOB-DETAILS-COMPANY-SET',
          company: resp.company,
        });
        dispatch({
          type: 'JOB-DETAILS-HEADER-SET',
          header: resp.header,
          generalSettings,
          debtors,
          storms,
          companies,
        });        
        dispatch({
          type: 'JOB-DETAILS-QUOTE-SET',
          lines: resp.lines
        });
        dispatch({
          type: 'JOB-GALLERY-SET',
          images: resp.images
        });
        dispatch({
          type: 'JOB-DETAILS-HEADER-REFRESH-DATESTAMPS',
        });
        dispatch({
          type: 'JOB-DETAILS-SET-FOUND',
          jobId,
          jobNumber: resp.header.jobNumber,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'JOB-DETAILS-SET-NULL'
});

const save = (API, jobId) => {
  return (dispatch, getState) => {
    dispatch({type: 'LOADING-INC'});
    const {
      params: {
        generalSettings,
        debtors: {index: debtors},
        storms: {index: storms},
        companies: {index: companies},
      }, jobDetails
    } = getState();
    const {
      header: {
        version, jobSettings, vip, vpp, flagged, name, address, state, postcode,
        comments, mobile, email, debtorId, stormId, companyId, claimNumber, excess, startDate,
        finishDate, assessmentDate, readyDate, pickupDate, fitmentDate, rego, vin,
        make, model, bodyShape, colour, bodyPaint, manDate, insuredValue, outOfScope,
        autoOrmEnabled, isIn, isOut, totalLoss, cashSettle,
        currentStartDate, currentFinishDate, convIsIn, authorised,
      },
      bookingType,
      category,
      totals: {
        pdr: pdrTotal,
        rr: rrTotal,
        repair: repairTotal,
        paint: paintTotal,
        part: partTotal,
        misc: miscTotal,
        sublet: subletTotal,
        quoted: quotedTotal,
        addPdr: addPdrTotal,
        catAdjustment: categoryAdjustment,
        catExtras: categoryExtraTotal,
        subtotal,
        gst,
        total
      }
    } = jobDetails;
    const header = {
      version, jobSettings, vip, vpp, flagged, name, address, state, postcode,
      comments, mobile, email, debtorId, stormId, companyId, claimNumber, excess,

      bookingDatesModified: currentStartDate !== startDate || currentFinishDate !== finishDate,
      startDate, finishDate,

      assessmentDate, readyDate, pickupDate, fitmentDate,
      rego, vin, make, model, bodyShape, colour, bodyPaint, manDate,
      insuredValue, outOfScope, autoOrmEnabled, isIn, isOut,
      totalLoss, cashSettle, convIsIn, authorised,

      bookingType, category,

      pdrTotal, rrTotal, repairTotal, paintTotal, partTotal, miscTotal,
      subletTotal, quotedTotal, categoryAdjustment, categoryExtraTotal,
      addPdrTotal, subtotal, gst, total,
    };
    const lines = Object.keys(jobDetails.quote)
      .reduce((acc, section) => {
        return acc.concat(
          jobDetails.quote[section]
            .filter(r => (
              (r.id !== null || r.deleted !== true) &&
              (r.description && r.description !== '')
            ))
            .map(({
              id,
              description,
              hours,
              rate,
              partNumber,
              qty,
              size,
              p2p,
              price,
              total,
              deleted,
              comments,
              categoryExtra,
              blend,
              paintGroup,
              source,
              replacePanel,
              partOutcome,
              partEta,
              onReport,
              ormStatus,
              ormComments,
            }, idx) => ({
              id,
              sort: idx,
              type: section,
              description,
              hours,
              rate,
              partNumber,
              qty,
              size,
              p2p,
              price: price === '' ? 0 : price,
              total: (
                total !== undefined &&
                total !== null ? Math.round(total * 100) : undefined
              ),
              deleted,
              comments,
              categoryExtra,
              blend,
              paintGroup,
              source,
              replacePanel,
              partOutcome,
              partEta,
              onReport,
              ormStatus,
              ormComments,
            }))
        );
      }, []);
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/details`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          header,
          lines
        })
      }
    )
      .then(res => {
        if (res.error === true) {
          if (res.hasOwnProperty('errors')) {
            dispatch({
              type: 'JOB-DETAILS-HEADER-SET-ERRORS',
              errors: res.errors.reduce((acc, {path}) => {
                const r = /^header\.(.*)$/.exec(path);
                if (r !== null) {
                  acc[`${r[1]}Error`] = true;
                }
                return acc;
              }, {})
            });
          }
          return res.description || false;
        }
        const {header, lines, company} = res;
        dispatch({
          type: 'JOB-DETAILS-HEADER-SET',
          header,
          generalSettings,
          debtors,
          storms,
          companies,
        });
        dispatch({
          type: 'JOB-DETAILS-QUOTE-SET',
          lines
        });
        dispatch({
          type: 'JOB-DETAILS-COMPANY-SET',
          company,
        });
        dispatch({
          type: 'JOB-DETAILS-HEADER-REFRESH-DATESTAMPS'
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const mapStateToProps = (state, props) => {
  const {jobId} = props;
  let jobValid = null;
  if (state.jobDetails.notFound === true)
    jobValid = false;
  else if (state.jobDetails.notFound === false) {
    jobValid = true;
  }
  if (jobValid !== true) {
    return {
      jobValid,
    };
  }
  const {
    authentication: {activeProfile, profiles},
    jobDetails: {
      modified,
      header: {
        jobNumber,
        importUrl,
        vip,
        vpp,
        flagged,
        isArchived,
        isInvoiced,
        isPaid,
      }
    },
  } = state;
  const {profile: {abilities: {canJobDetailsSave}}} = profiles[activeProfile];
  return {
    jobValid,
    canJobDetailsSave,
    jobId,
    jobNumber,
    importUrl,
    modified,
    vip,
    vpp,
    flagged,
    isArchived,
    isInvoiced,
    isPaid,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: jobId => dispatch(load(props.API, jobId)),
  onUnload: () => dispatch(unload()),
  onChange: (changes) => dispatch(change(changes)),
  onSave: jobId => dispatch(save(props.API, jobId))
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Details)
);
