import React from 'react';

import colors from 'colors';

import Field from 'components/Field.jsx';
import Select from 'components/Select.jsx';


export default class SettingsMailbox extends React.Component {
  constructor(opts) {
    super(opts);
    this.onChangeDebtorId = this.onChange.bind(this, 'debtorId');
    this.onChangeDefaultStorm = this.onChange.bind(this, 'defaultStorm');
    this.onChangeDefaultCompany = this.onChange.bind(this, 'defaultCompany');
    this.onChangeDefaultRegoState = this.onChange.bind(this, 'defaultRegoState');
    this.onToggleDisabled = this.onToggleDisabled.bind(this);
    this.onToggleLimitRepxUpdate= this.onToggleLimitRepxUpdate.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  onChange(field, e) {
    this.props.onChange({
      [field]: e,
      [`${field}Error`]: false
    });
  }
  onToggleLimitRepxUpdate(e) {
    this.props.onChange({
      limitRepxUpdate: !this.props.limitRepxUpdate
    });
  }
  onToggleDisabled(e) {
    this.props.onChange({
      disabled: !this.props.disabled
    });
  }
  onDelete() {
    const {id, onDelete} = this.props;
    onDelete(id);
  }
  onSave() {
    const {
      onSave,
      id, name, username, password,
      repxUsername, repxPassword,
      debtorId, defaultStorm,
      defaultRegoState, disabled,
      defaultCompany,
      limitRepxUpdate
    } = this.props;
    onSave(id, {
      name,
      username,
      password: (password && password !== '' ? password : null),
      repxUsername: (repxUsername && repxUsername !== '' ? repxUsername : null),
      repxPassword: (repxPassword && repxPassword !== '' ? repxPassword : null),
      debtorId,
      defaultStorm,
      defaultRegoState,
      defaultCompany,
      limitRepxUpdate,
      disabled,
    });
  }
  render() {
    console.log('settingsmailbox:render');
    const {onClose} = this.props;
    const {
      debtors,
      storms,
      companies,
      id,
      name, nameError,
      username, usernameError,
      password, passwordError,
      repxUsername, repxUsernameError,
      repxPassword, repxPasswordError,
      debtorId, debtorIdError,
      defaultStorm, defaultStormError,
      defaultRegoState, defaultRegoStateError,
      defaultCompany, defaultCompanyError,
      limitRepxUpdate, disabled,
    } = this.props;    
    return (
      <React.Fragment>
        <div className='modal-body'>
          <Field
            title='Name'
            background={colors.grey1}
            uppercaseOnly={true}
            value={name}
            error={nameError}
            onChange={this.onChange.bind(this, 'name')} />
          <Field
            title='ORM username'
            background={colors.grey1}
            value={username}
            error={usernameError}
            onChange={this.onChange.bind(this, 'username')} />
          <Field
            type='password'
            background={colors.grey1}
            title='ORM password'
            value={password}
            error={passwordError}
            onChange={this.onChange.bind(this, 'password')} />
          <Field
            title='RepX username'
            background={colors.grey1}
            value={repxUsername}
            error={repxUsernameError}
            onChange={this.onChange.bind(this, 'repxUsername')} />
          <Field
            type='password'
            background={colors.grey1}
            title='RepX password'
            value={repxPassword}
            error={repxPasswordError}
            onChange={this.onChange.bind(this, 'repxPassword')} />
          <Select
            title='Debtor'
            placeholder='select a debtor'
            background={colors.grey1}
            options={debtors}
            textField='name'
            selected={debtorId}
            error={debtorIdError}
            onChange={this.onChangeDebtorId}
          />
          <Select
            title='Default storm'
            placeholder='select a storm'
            background={colors.grey1}
            options={storms}
            textField='name'
            selected={defaultStorm}
            error={defaultStormError}
            onChange={this.onChangeDefaultStorm}
          />
          <Select
            title='Default company'
            placeholder='select a company'
            background={colors.grey1}
            options={companies}
            textField='name'
            selected={defaultCompany}
            error={defaultCompanyError}
            onChange={this.onChangeDefaultCompany}
          />
          <Select
            title='Default rego state'
            placeholder='select a rego state'
            background={colors.grey1}
            options={['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'NZ']}
            selected={defaultRegoState}
            error={defaultRegoStateError}
            onChange={this.onChangeDefaultRegoState}
          />
          <div className='mt-3'>
            <button
              className={limitRepxUpdate ? 'btn btn-outline-secondary w-100' : 'btn btn-secondary w-100'}
              onClick={this.onToggleLimitRepxUpdate}
            >{limitRepxUpdate ? 'RepX Update Limited' : 'RepX Update No Limit'}</button>
          </div>
          <div className='mt-3'>
            <button
              className={disabled ? 'btn btn-outline-secondary w-100' : 'btn btn-secondary w-100'}
              onClick={this.onToggleDisabled}
            >{disabled ? 'Disabled' : 'Enabled'}</button>
          </div>
        </div>
        <div className='modal-footer'>
          {id !== 'new' &&
            <button type='button' className='btn btn-danger mr-auto' onClick={this.onDelete}>Delete</button>
          }
          <button type='button' className='btn btn-secondary' onClick={onClose}>Close</button>
          <button type='button' className='btn btn-primary' onClick={this.onSave}>Save</button>
        </div>
      </React.Fragment>
    );
  }
};
