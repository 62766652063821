import React from 'react';
import * as Icons from 'react-feather';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import colors from 'colors';

import ExpandToggle from 'icons/expand-toggle.jsx';
import Autocomplete from 'components/Autocomplete.jsx';
import CalendarInput from 'components/CalendarInput.jsx';
import Select from 'components/Select.jsx';


const PART_OUTCOMES = [
  'BACKORDERED',
  'DAMAGED',
  'DNO',
  'FITTED',
  'WRONG PART',
];
const SOURCES = ['OEM', 'After Market', 'Exchange', 'Used'/*, 'OE Parallel'*/];
const PANELS = [
  'FRONT-BUMPER', 'BONNET', 'BOOT', 'PLENUM', 'TURRET', 'LHF-GUARD',
  'LHF-DOOR', 'LHR-DOOR', 'LHR-QTR', 'RHF-GUARD', 'RHF-DOOR',
  'RHR-DOOR', 'RHR-QTR', 'LH-CANTRAIL', 'RH-CANTRAIL',
  'LH-CAB-PILLAR', 'RH-CAB-PILLAR', 'TAILGATE', 'UPPER-TAILGATE',
  'LOWER-TAILGATE', 'REAR-BUMPER'
].sort();


const Input = styled.input`
  border-color: ${opts => opts.error ? colors.red : '#fff'} !important;
  text-transform: ${opts => opts.uppercaseOnly ? 'uppercase' : 'none'};
  :read-only {
    background-color: ${colors.grey2} !important;
    border-color: ${colors.grey2} !important;
  }
`;
const Textarea = styled.textarea`
  border-color: ${opts => opts.error ? colors.red : '#fff'} !important;
`;
const AddButtonRow = styled.tr`
  & td svg {
    opacity: 0;
  }
  & td div {
    display: none;
  }
  & td svg:hover {
    opacity: 1;
    margin-top: -2px;
    + div {
      display: block;
      visibility: visible;
    }
  }
`;
const AddButton = styled(Icons.Plus)`
  cursor: pointer;
  stroke: ${colors.grey4};
  position: absolute;
  margin-top: -8px;
  margin-left: -25px;
  height: 16px;
  width: 16px;
`;
const Filler = styled.div`
  height: 4px;
  background-color: ${colors.grey2};
  border-radius: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
`;
const DeleteButton = styled(Icons.X)`
  cursor: pointer;
  stroke: ${colors.red};
`;
const Tr = styled.tr`
  input, input:read-only {
    ${opts => opts.danger && `color: ${colors.red} !important;`}
    ${opts => opts.partOutcome === 'FITTED' && `color: ${colors.green} !important;`}
    ${opts => opts.warning && `
      background-color: ${colors.orangeExtraLight} !important;
      border-color: ${colors.orangeExtraLight} !important;
    `}
  }
`;


export default class PartRow extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      expanded: false
    };
    this.onAddRow = this.onAddRow.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onPartOutcome = this.onPartOutcome.bind(this);
    this.partOutcomeId = `part-outcome-${opts.index}`;
    this.categoryExtraId = `part-categoryextra-${opts.index}`;
    this.onReportId = `part-onreport-${opts.index}`;
    this.commentId = `part-comment-${opts.index}`;
    this.ormCommentId = `part-orm-comment-${opts.index}`;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state !== nextState ||
      this.props.description !== nextProps.description ||
      this.props.partNumber !== nextProps.partNumber ||
      this.props.qty !== nextProps.qty ||
      this.props.price !== nextProps.price ||
      this.props.deleted !== nextProps.deleted ||
      this.props.comments !== nextProps.comments ||
      this.props.ormComments !== nextProps.ormComments ||
      this.props.ormStatus !== nextProps.ormStatus ||
      this.props.categoryExtra !== nextProps.categoryExtra ||
      this.props.source !== nextProps.source ||
      this.props.replacePanel !== nextProps.replacePanel ||
      this.props.partOutcome !== nextProps.partOutcome ||
      this.props.partEta !== nextProps.partEta ||
      this.props.onReport !== nextProps.onReport
    );
  }
  onToggle() {
    this.setState({
      expanded: !this.state.expanded
    });
  }
  onDelete() {
    const {index, onDelete} = this.props;
    onDelete(index);
  }
  onAddRow() {
    const {index, onAddRowAfter} = this.props;
    onAddRowAfter(index + 1);
  }
  onFocus() {
    const {index, isLast, onAddRowAfter} = this.props;
    if (isLast === true) {
      onAddRowAfter(index);
    }
  }
  onFilter(v) {
    const {lineItems: {index: ngramIndex, list: itemList}} = this.props;
    const {list} = v
      .replace(/[^a-z0-9\s]/gi, '')
      .split(' ')
      .filter(s => s.length)
      .reduce((acc, s) => {
        for (let i in s) {
          for (let j = 2; j <= Math.min(8, s.length - i); j++) {
            const ngram = s.substr(i, j);
            if (ngramIndex.hasOwnProperty(ngram)) {
              ngramIndex[ngram].forEach(idx => {
                if (!acc.index.hasOwnProperty(idx)) {
                  acc.index[idx] = acc.list.length;
                  acc.list.push({
                    idx,
                    count: 0
                  });
                }
                acc.list[acc.index[idx]].count += ngram.length;
              });
            }
          }
        }
        return acc;
      }, {index: {}, list: []});
    return list
      .sort((a, b) => (b.count - a.count))
      .slice(0, 20)
      .map((ngram, id) => {
        const {_id, description, partPrice} = itemList[ngram.idx];
        return {
          id,
          _id,
          text: description,
          price: partPrice,
        };
      });
  }
  onChange(field, e) {
    const {index, ormQty, ormPrice, ormStatus, onChange} = this.props;
    if (field === 'description') {
      if (typeof e === 'string') {
        onChange('part', index, {
          description: e
        });
      } else {
        onChange('part', index, {
          _id: e._id,
          partNumber: e.partNumber,
          description: e.text,
          qty: 1,
          price: e.price,
        });
      }
    } else if (field === 'categoryExtra' || field === 'onReport') {
      onChange('part', index, {
        [field]: e.target.checked
      });
    } else if (
      ['source', 'replacePanel', 'partOutcome', 'partEta'].indexOf(field) !== -1
    ) {
      onChange('part', index, {
        [field]: e
      });
    } else if (field === 'ormAccept') {
      onChange('part', index, {
        qty: ormStatus === 'DELETED' ? 0 : ormQty,
        price: ormStatus === 'DELETED' ? 0 : ormPrice,
        ormStatus: ['DELETED', 'ACKNOWLEDGEDDELETE'].indexOf(ormStatus) !== -1 ? 'ACKNOWLEDGEDDELETE' : 'ACCEPTED',
      });
    } else {
      onChange('part', index, {
        [field]: e.target.value
      });
    }
  }
  onPartOutcome(e) {
    const {id, index, onPartOutcome} = this.props;
    onPartOutcome(index, id, e)
      .then(res => {
        if (res !== true) {
          toast.error('Error encountered while updating job.');
        } else {
          toast.success('Job successfully updated.');
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('Error encountered while updating job.');
      });
  }
  render() {
    const {expanded} = this.state;
    const {
      isLast,
      description,
      partNumber,
      qty,
      price,
      deleted,
      comments,
      categoryExtra,
      source,
      replacePanel,
      partOutcome,
      partEta,
      partEtaStr,
      location,
      totalStr,
      showFitted,
      showPrices,
      showDelete,
      hasOrm,
      onReport,
      ormStatus,
      ormQty,
      ormPrice,
      ormTotalStr,
      ormComments,
    } = this.props;
    if (deleted === true) return null;
    const ormChanged = hasOrm === true && ['CHANGED', 'DELETED'].indexOf(ormStatus) !== -1;
    let _ormStatus = onReport === true ? 'ONREPORT' : (hasOrm ? ormStatus : null);
    return (
      <React.Fragment>
        <Tr partOutcome={partOutcome} warning={categoryExtra === true}>
          <td>
            {!isLast &&
              <ExpandToggle
                className='cursor-pointer'
                onClick={this.onToggle}
                expanded={expanded}
                status={_ormStatus}
                width={18}
                height={18}
              />
            }
          </td>
          <td className='pr-1'>
            <Autocomplete
              className='m-0'
              value={description || ''}
              uppercaseOnly={true}
              onFocus={this.onFocus}
              onChange={this.onChange.bind(this, 'description')}
              onFilter={this.onFilter}
            />
          </td>
          <td className='px-1'>
            <Input
              type='text'
              className='form-control p-0 m-0 text-uppercase text-center'
              value={partNumber || ''}
              onFocus={this.onFocus}
              onChange={this.onChange.bind(this, 'partNumber')}
            />
          </td>
          <td className='px-1'>
            <Input
              type='text'
              className='form-control p-0 m-0 text-uppercase text-center'
              value={location || partEtaStr || ''}
              readOnly={true}
            />
          </td>
          {isLast === false && showFitted && (
            <td className='px-1'>
              <Select
                align='right'
                placeholder=''
                options={PART_OUTCOMES}
                selected={partOutcome}
                onChange={this.onPartOutcome}
              />
            </td>
          )}
          <td className='px-1'>
            <Input
              type='text'
              className='form-control p-0 m-0 text-uppercase text-center'
              value={qty === undefined || qty === null ? '' : qty}
              onFocus={this.onFocus}
              onChange={this.onChange.bind(this, 'qty')}
            />
          </td>
          {showPrices && (
            <React.Fragment>
              <td className='px-1'>
                <Input
                  type='text'
                  className='form-control p-0 m-0 text-uppercase text-center'
                  value={price === undefined || price === null ? '' : price}
                  onFocus={this.onFocus}
                  onChange={this.onChange.bind(this, 'price')}
                />
              </td>
              <td className='px-1'>
                <Input
                  type='text'
                  className='form-control p-0 m-0 text-uppercase text-center'
                  value={totalStr === undefined || totalStr === null ? '' : totalStr}
                  readOnly={true}
                />
              </td>
            </React.Fragment>
          )}
          {showDelete && (
            <td className='text-right pl-2'>
              {!isLast && <DeleteButton
                onClick={this.onDelete}
                width={16} height={16} />
              }
            </td>
          )}
        </Tr>
        {!isLast && expanded &&
          <tr>
            <td />
            <td colSpan={3 + (showFitted ? 1 : 0) + (ormChanged === false && (showPrices === true ? 3 : 1))}>
              <div className='d-flex flex-row align-items-center mt-2'>
                <div className='form-check mr-3'>
                  <Input
                    className='form-check-input'
                    type='checkbox'
                    checked={categoryExtra}
                    id={this.categoryExtraId}
                    onFocus={this.onFocus}
                    onChange={this.onChange.bind(this, 'categoryExtra')}
                  />
                  <label className='form-check-label' htmlFor={this.categoryExtraId}>
                    <small>Category extra</small>
                  </label>
                </div>
                {hasOrm === true && (
                  <div className='form-check mr-3'>
                    <Input
                      className='form-check-input'
                      type='checkbox'
                      checked={onReport}
                      id={this.onReportId}
                      onFocus={this.onFocus}
                      onChange={this.onChange.bind(this, 'onReport')}
                    />
                    <label className='form-check-label' htmlFor={this.onReportId}>
                      <small>On report</small>
                    </label>
                  </div>
                )}
              </div>
              <div className='d-flex flex-row align-items-center mt-2'>
                <Select
                  className='mr-3'
                  placeholder='select a source'
                  options={SOURCES}
                  selected={source}
                  onChange={this.onChange.bind(this, 'source')}
                />
                <Select
                  className='mr-3'
                  placeholder='select a panel'
                  options={PANELS}
                  selected={replacePanel}
                  onChange={this.onChange.bind(this, 'replacePanel')}
                />
                {showFitted !== true && (
                  <Select
                    className='mr-3'
                    placeholder='select an outcome'
                    options={PART_OUTCOMES}
                    selected={partOutcome}
                    onChange={this.onChange.bind(this, 'partOutcome')}
                  />
                )}
                <CalendarInput
                  className='mr-3'
                  placeholder='eta'
                  selected={partEta}
                  background='#fff'
                  showClear={true}
                  onChange={this.onChange.bind(this, 'partEta')}
                />
              </div>
              <div className='mb-2'>
                <label
                  className='position-static mb-0'
                  htmlFor={this.commentId}
                ><small>Comments</small></label>
                <Textarea
                  id={this.commentId}
                  type='text'
                  rows={2}
                  className='form-control p-0'
                  onFocus={this.onFocus}
                  onChange={this.onChange.bind(this, 'comments')}
                  value={comments}
                />
              </div>
              {hasOrm === true && (
                <div className='mb-2'>
                  <label
                    className='position-static mb-0'
                    htmlFor={this.ormCommentId}
                  ><small>ORM Comments</small></label>
                  <Textarea
                    id={this.ormCommentId}
                    type='text'
                    rows={2}
                    className='form-control p-0'
                    onFocus={this.onFocus}
                    onChange={this.onChange.bind(this, 'ormComments')}
                    value={ormComments}
                  />
                </div>
              )}
            </td>
            {ormChanged === true && showPrices === true && (
              <td colSpan={3} className='align-top'>
                <table
                  className='w-100 mt-2'
                  style={{textDecoration: ormStatus === 'DELETED' ? 'line-through' : 'inherit'}}
                >
                  <tbody>
                    <tr>
                      <td width={80} className='text-center'>
                        {ormQty}
                      </td>
                      <td width={80} className='text-center'>
                        {ormPrice}
                      </td>
                      <td width={80} className='text-center'>
                        {ormTotalStr}
                      </td>
                    </tr>
                    <tr>
                      <td className='text-right' colSpan={3}>
                        <button
                          type='button'
                          className={ormStatus === 'DELETED' ? 'btn btn-danger ml-1 mt-2' : 'btn btn-success ml-1 mt-2'}
                          onClick={this.onChange.bind(this, 'ormAccept')}
                        >Accept</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            )}
            {showDelete && (
              <td />
            )}
          </tr>
        }
        {!isLast && (
          <AddButtonRow>
            <td padding='0' />
            <td padding='0' colSpan={4 + (showPrices ? 2 : 0) + (showFitted ? 1 : 0)}>
              <AddButton
                onClick={this.onAddRow}
                width={16}
                height={16}
              />
              <Filler />
            </td>
          </AddButtonRow>
        )}
      </React.Fragment>
    );
  }
};
