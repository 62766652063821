import moment from 'm';
import numeral from 'numeral';

import TruncateString from 'utils/truncate-string.js';


let toggleStates = {};
const cv = window.localStorage.getItem('outstanding-invoices:toggleStates');
if (cv) {
  toggleStates = JSON.parse(cv);
}

const BUCKETS = [
  {title: 'To be quoted', eval: j => j.quoteDate === null},
  {title: 'Waiting for authority', eval: j => j.authoriseDate === null && j.authorityDeclineDate === null},
  {title: 'Authority declined', eval: j => j.authoriseDate === null && j.authorityDeclineDate !== null},
  {title: 'To be invoiced', eval: j => j.invoiceDate === null},
  {
    title: 'Waiting for payment authority',
    eval: j => j.paymentAuthoriseDate === null && j.paymentDeclineDate === null,
  },
  {
    title: 'Payment declined',
    eval: j => j.paymentAuthoriseDate === null && j.paymentDeclineDate !== null,
  },
  {
    title: 'Waiting for payment',
    eval: j => true,
  },
];

export default (state = {
  notFound: null,
  debtors: [],
  toggleStates,
}, action) => {
  switch (action.type) {
    case 'OUTSTANDING-INVOICES-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        debtors: []
      };
    case 'OUTSTANDING-INVOICES-SET':
    {
      const {jobs} = action;
      const {list: debtors, total} = jobs.reduce((acc, j) => {
        if (!acc.index.hasOwnProperty(j.debtorId)) {
          acc.index[j.debtorId] = acc.list.length;
          acc.list.push({
            id: j.debtorId,
            locations: [],
            total: 0,
          });
        }

        const debtor = acc.list[acc.index[j.debtorId]];

        const lid = [j.debtorId, j.locationId].join(':');
        if (!acc.lidIndex.hasOwnProperty(lid)) {
          acc.lidIndex[lid] = debtor.locations.length;
          debtor.locations.push({
            id: j.locationId,
            buckets: BUCKETS.map(b => ({...b, jobs: [], total: 0})),
            total: 0,
          });
        }

        const location = debtor.locations[acc.lidIndex[lid]];

        for (const b of location.buckets) {
          if (b.eval(j)) {
            b.total += j.subtotal;
            b.jobs.push({
              ...j,
              makeModel: TruncateString([j.make || '', j.model || ''].join(' ')),
              subtotalStr: numeral(j.subtotal / 100).format('$0,0.00'),
            });
            break;
          }
        }

        location.total += j.subtotal;
        debtor.total += j.subtotal;

        acc.total += j.subtotal;

        return acc;
      }, {
        list: [],
        index: {},
        lidIndex: {},
        total: 0,
      });
      debtors.forEach(d => {
        d.locations.sort((a, b) => (b.total - a.total));
        d.totalStr = numeral(d.total / 100).format('$0,0.00');
        d.locations.forEach(l => {
          l.totalStr = numeral(l.total / 100).format('$0,0.00');
          l.buckets.forEach(b => {
            b.jobs.sort((a, b) => (b.subtotal - a.subtotal));
            b.totalStr = numeral(b.total / 100).format('$0,0.00');
          });
        });
      });
      debtors.sort((a, b) => b.total - a.total);
      return {
        ...state,
        notFound: false,
        debtors,
      };
    }
    case 'OUTSTANDING-INVOICES-TOGGLE':
    {
      const {debtorId, locationId, sectionId} = action;
      const id = [debtorId, locationId, sectionId].join(':');
      const newToggleStates = {
        ...state.toggleStates,
        [id]: !state.toggleStates[id]
      };
      window.localStorage.setItem('outstanding-invoices:toggleStates', JSON.stringify(newToggleStates));
      return {
        ...state,
        toggleStates: newToggleStates,
      };
    }
    default:
      return state;
  }
};
