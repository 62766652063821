import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import ReportsStormActivity from './component.jsx';


const load = (API, stormId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/reports/storm-activity/${stormId}`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'ADMIN-REPORTS-STORM-ACTIVITY-SET-NOT-FOUND'
          });
          return null;
        }
        dispatch({
          type: 'ADMIN-REPORTS-STORM-ACTIVITY-SET',
          ...resp
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const unload = () => ({
  type: 'ADMIN-REPORTS-STORM-ACTIVITY-SET-NULL'
});

const changeStormId = stormId => ({
  type: 'ADMIN-REPORTS-STORM-ACTIVITY-CHANGE-STORM-ID',
  stormId,
});


const mapStateToProps = (state, props) => {
  const {
    adminReportsStormActivity: {notFound, stormId, report},
    params: {
      storms: {list: storms},
    }
  } = state;
  return {
    notFound,
    storms,
    stormId,
    report,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: stormId => dispatch(load(props.API, stormId)),
  onUnload: () => dispatch(unload()),
  onChangeStormId: stormId => dispatch(changeStormId(stormId)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReportsStormActivity)
);
