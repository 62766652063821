import {connect} from 'react-redux';

import env from 'env';
import {withApi} from 'components/API.jsx';
import OutstandingInvoices from './component.jsx';


const load = (API, locationId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(`${env.API_SERVER}/v1/internal/outstanding-invoices`, {method: 'GET'})
      .then(resp => {
        if (resp && resp.error === true) {
          dispatch({
            type: 'OUTSTANDING-INVOICES-SET-NOT-FOUND'
          });
          return null;
        }
        const {jobs} = resp;
        dispatch({
          type: 'OUTSTANDING-INVOICES-SET',
          jobs,
        });
        return null;
      })
      .catch(e => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const toggle = (debtorId, locationId, sectionId) => ({
  type: 'OUTSTANDING-INVOICES-TOGGLE',
  debtorId,
  locationId,
  sectionId,
});

const mapStateToProps = (state, props) => {
  const {
    params: {
      debtors: {
        index: debtorIndex,
      },
      locations: {
        index: locationIndex,
      },
    },
    outstandingInvoices,
  } = state;
  return {
    ...outstandingInvoices,
    debtorIndex,
    locationIndex,
  };
};

const mapDispatchToProps = (dispatch, {API}) => ({
  onLoad: () => dispatch(load(API)),
  onToggle: (debtorId, locationId, sectionId) => dispatch(toggle(debtorId, locationId, sectionId)),
});

export default withApi(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OutstandingInvoices)
);
