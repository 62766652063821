import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import env from 'env';
import {withApi} from 'components/API.jsx';
import JobTitle from './component.jsx';


const duplicate = (API, history, jobId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/duplicate`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({jobId})
      }
    )
      .then(res => {
        if (res.error === true) return false;
        history.push(`/job/${res.jobId}`);
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const orm = (API, jobId, action) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/orm`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({action})
      }
    )
      .then(res => {
        if (res.error === true) {
          if (res.hasOwnProperty('errors')) {
            dispatch({
              type: 'JOB-DETAILS-HEADER-SET-ERRORS',
              errors: res.errors.reduce((acc, {path}) => {
                acc[`${path}Error`] = true;
                return acc;
              }, {})
            });
          }
          return false;
        }
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const cancelInvoice = (API, jobId) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/invoice`,
      {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json'
        },
      }
    )
      .then(res => {
        if (res.error === true) {
          return false;
        }
        dispatch({
          type: 'JOB-DETAILS-HEADER-SET-INVOICE-DATE',
          ...res,
        });
        dispatch({
          type: 'JOB-DETAILS-HEADER-REFRESH-DATESTAMPS',
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const archive = (API, jobId, set) => {
  return dispatch => {
    dispatch({type: 'LOADING-INC'});
    return API.request(
      `${env.API_SERVER}/v1/internal/job/${jobId}/archive`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({set})
      }
    )
      .then(res => {
        if (res.error === true) {
          return false;
        }
        dispatch({
          type: 'JOB-DETAILS-HEADER-SET-ARCHIVE-DATE',
          archiveDate: res.archiveDate
        });
        dispatch({
          type: 'JOB-DETAILS-HEADER-REFRESH-DATESTAMPS',
        });
        return true;
      })
      .finally(() => {
        dispatch({type: 'LOADING-DEC'});
      });
  };
};

const importOpen = () => ({
  type: 'JOB-IMPORT-OPEN',
});

const infosheetOpen = () => ({
  type: 'JOB-INFOSHEET-OPEN'
});

const jobPrint = mode => ({
  type: 'JOB-PRINT-OPEN',
  mode,
});

const lightTunnelOpen = () => ({
  type: 'JOB-LIGHT-TUNNEL-OPEN',
});

const invoiceOpen = () => ({
  type: 'JOB-INVOICE-OPEN',
});

const excessInvoiceOpen = () => ({
  type: 'JOB-EXCESS-INVOICE-OPEN',
});

const mapStateToProps = (state, props) => {
  const {
    authentication: {activeProfile, profiles},
    params: {
      lightTunnels,
      debtors: {index: debtors},
    },
    jobDetails: {
      customerPortalUrl,
      claimPortalUrl,
    },

  } = state;

  const claimNumber = state.jobDetails.header ? state.jobDetails.header.claimNumber : undefined

  const {
    profile: {
      abilities: {
        canAddJob,
        canJobAddImages,
        canJobArchive,
        canJobDetailsSave,
        canJobDetailsViewQuotePrices,
        canJobExcessInvoice,
        canJobInvoice,
        canJobCancelInvoice,
        canJobOrm,
        canViewCustomerPortal,
        canViewClaimPortal,
        canViewOrmPortal,
      }
    }
  } = profiles[activeProfile];
  const hasDropdown = typeof state.jobDetails === 'object' && typeof state.jobDetails.header === 'object';
  const hasOrm = (
    hasDropdown &&
    (
      state.jobDetails.header.hasOrm === true ||
      (
        state.jobDetails.header.debtorId !== null &&
        debtors[state.jobDetails.header.debtorId] &&
        debtors[state.jobDetails.header.debtorId].canOrm === true
      )
    )
  );
  return {
    canAddJob,
    canLightTunnel: canJobAddImages && lightTunnels.length !== 0,
    canJobArchive,
    canJobDetailsSave,
    canJobDetailsViewQuotePrices,
    canJobExcessInvoice,
    canJobInvoice,
    canJobCancelInvoice,
    canJobOrm,
    canViewCustomerPortal,
    canViewClaimPortal,
    canViewOrmPortal,
    hasDropdown,
    hasOrm,
    customerPortalUrl,
    claimPortalUrl,
    claimNumber,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onDuplicate: jobId => dispatch(duplicate(props.API, props.history, jobId)),
  onImport: () => dispatch(importOpen()),
  onInfosheet: () => dispatch(infosheetOpen()),
  onJobsheet: () => dispatch(jobPrint('jobsheet')),
  onLightTunnel: () => dispatch(lightTunnelOpen()),
  onQuote: () => dispatch(jobPrint('quote')),
  onInvoice: () => dispatch(invoiceOpen()),
  onExcessInvoice: () => dispatch(excessInvoiceOpen()),
  onOrm: (jobId, action) => dispatch(orm(props.API, jobId, action)),
  onCancelInvoice: jobId => dispatch(cancelInvoice(props.API, jobId)),
  onArchive: (jobId, set) => dispatch(archive(props.API, jobId, set)),
});

export default withApi(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(JobTitle)
  )
);
