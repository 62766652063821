import React from 'react';
import {NavLink} from 'react-router-dom';
import * as Icons from 'react-feather';
import styled from 'styled-components';

import colors from 'colors';


const ErrorContainer = styled.div`
  color: ${colors.red};
  & svg {
    stroke: ${colors.red};
  }
`;
const Container = styled.div`
  overflow-y: auto;
  font-size: 0.9rem;
`;
const Section = styled.div`
  border-radius: 0.5rem;
  background-color: ${opts => opts.empty ? 'inherit' : opts.color};
  box-shadow: ${opts => opts.open ? '0px 8px 15px -4px rgba(180, 180, 180, 0.5)' : 'none'};
  :last-of-type {
    margin-bottom: 0px !important;
  }
  @media print {
    display: ${opts => opts.open ? 'block' : 'none'};
  }
`;
const SectionHeader = styled.div`
  cursor: pointer;
`;
const SectionTitle = styled.h6`
  color: ${colors.primary};
`;
const JobCard = styled.div`
  border-radius: 0.5rem;
  background: ${opts => opts.color};
  border: 1px solid ${opts => opts.modified ? colors.red : opts.color};
  @media print {
    border: 1px solid ${colors.body};
  }
`;
const JobImg = styled.img`
  border-radius: 0.25rem;
  margin-left: 0px !important;
  max-height: 48px;
  width: auto;
`;


export default class OutstandingInvoices extends React.Component {
  constructor(opts) {
    super(opts);
    //this.state = null;
    //const {debtors} = this.props;
  }
  componentDidMount() {
    const {onLoad} = this.props;
    onLoad();
  }
  onToggle(e) {
    const debtorId = e.currentTarget.getAttribute('data-debtor-id');
    const locationId = e.currentTarget.getAttribute('data-location-id');
    const sectionId = e.currentTarget.getAttribute('data-section-id');
    const {onToggle} = this.props;
    onToggle(debtorId, locationId, sectionId);
  }
  renderJob(job, i) {
    return (
      <div className='col-sm-6 col-md-4 col-lg-3 mt-2' key={i}>
        <JobCard
          className='d-flex flex-column mx-1 h-100 p-2'
          color={colors.white}
        >
            <div className='d-flex flex-row justify-content-between'>
              <div>
                <NavLink to={`/job/${job.id}`} className='text-body font-weight-bold'>{job.jobNumber}</NavLink>
              </div>
              <div className='text-center'>{job.rego}</div>
              <div className='text-right'>{job.claimNumber}</div>
            </div>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div className='flex-grow-1 text-truncate text-uppercase'>{job.makeModel}</div>
              <div className='text-right'>{job.subtotalStr}</div>
            </div>
        </JobCard>
      </div>
    );
  }
  renderBucket(debtorId, locationId, {title, jobs, totalStr}, i) {
    if (!jobs.length) return null;
    const {toggleStates} = this.props;
    const toggleId = [debtorId, locationId, title].join(':');
    const open = toggleStates[toggleId] === true;
    const Chevron = open ? Icons.ChevronUp : Icons.ChevronDown;
    return (
      <Section key={i} className='mb-2' open={open} color={colors.grey1}>
        <SectionHeader
          className='d-flex flex-row justify-content-between align-items-center p-2'
          onClick={this.onToggle.bind(this)}
          data-debtor-id={debtorId}
          data-location-id={locationId}
          data-section-id={title}
        >
          <SectionTitle className='flex-grow-1 d-flex flex-row justify-content-between align-items-center mb-0 ml-1 mr-4'>
            <div>{title}</div>
            <div>{totalStr}</div>
          </SectionTitle>
          <Chevron color={colors.primary} />
        </SectionHeader>
        {open && (
          <div className='row no-gutters ml-4 pt-2 pb-4'>{jobs.map(this.renderJob.bind(this))}</div>
        )}
      </Section>
    );
  }
  renderLocation(debtorId, {id, buckets, totalStr}, i) {
    const {locationIndex, toggleStates} = this.props;
    const toggleId = [debtorId, id, null].join(':');
    const open = toggleStates[toggleId] === true;
    const Chevron = open ? Icons.ChevronUp : Icons.ChevronDown;
    return (
      <Section key={i} className='mb-2' open={open} color={colors.grey2}>
        <SectionHeader
          className='d-flex flex-row justify-content-between align-items-center p-2'
          onClick={this.onToggle.bind(this)}
          data-debtor-id={debtorId}
          data-location-id={id}
        >
          <SectionTitle className='flex-grow-1 d-flex flex-row justify-content-between align-items-center mb-0 ml-1 mr-4'>
            <div>{locationIndex[id].name}</div>
            <div>{totalStr}</div>
          </SectionTitle>
          <Chevron color={colors.primary} />
        </SectionHeader>
        {open && (
          <div className='ml-4 pt-2 pb-4'>{buckets.map(this.renderBucket.bind(this, debtorId, id))}</div>
        )}
      </Section>
    );
  }
  renderDebtor({id, locations, totalStr}, i) {
    const {debtorIndex, toggleStates} = this.props;
    const toggleId = [id, null, null].join(':');
    const open = toggleStates[toggleId] === true;
    const Chevron = open ? Icons.ChevronUp : Icons.ChevronDown;
    return (
      <Section key={i} className='mb-2' open={open} color={colors.grey3}>
        <SectionHeader
          className='d-flex flex-row justify-content-between align-items-center p-2'
          onClick={this.onToggle.bind(this)}
          data-debtor-id={id}
        >
          <SectionTitle className='flex-grow-1 d-flex flex-row justify-content-between align-items-center mb-0 ml-1 mr-4'>
            <div>{debtorIndex[id].name}</div>
            <div>{totalStr}</div>
          </SectionTitle>
          <Chevron color={colors.primary} />
        </SectionHeader>
        {open && (
          <div className='ml-4 pt-2 pb-4'>{locations.map(this.renderLocation.bind(this, id))}</div>
        )}
      </Section>
    );
  }
  render() {
    console.log('outstandinginvoices:render');
    const {notFound, debtors} = this.props;
    if (notFound === true) {
      return (
        <ErrorContainer
          className='w-100 flex-grow-1 d-flex flex-row justify-content-center align-items-center'>
          <Icons.AlertOctagon width={64} height={64} className='mr-3' />
          <div>Failed to load outstsanding invoices.</div>
        </ErrorContainer>
      );
    }
    return (
      <Container className='flex-grow-1 p-3'>
        <h5 className='mb-3'>Outstanding invoices</h5>
        {debtors.map(this.renderDebtor.bind(this))}
      </Container>
    );
  }
};
